<template>
  <!-- BODY -->
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <b-overlay
      :show="showOverlay"
      variant="white"
    >
      <!-- Form -->
      <b-form
        class="p-2 close-cargo-form"
        @submit.prevent="handleSubmit(submitForm)"
        @reset.prevent="resetFormData"
      >
        <div>

          <h4 class="text-uppercase font-weight-bold mb-1 rounded form-section-title bg-danger text-white">
            ATTENZIONE! Chiusura piano di carico
          </h4>

          <div v-if="cargo_data.c_carico">
            <p>
              <strong>CARICO:</strong> {{ cargo_data.c_carico }}
            </p>
          </div>
          <div v-if="cargo_data.c_carico">
            <p>
              <strong>KM STIMATI:</strong> {{ cargo_data.km_previsti }} Km
            </p>
          </div>

          <!-- Note -->
          <validation-provider
            #default="validationContext"
            rules="required|integer"
            name="Kilometri effettivi"
            class="d-block"
          >

            <div class="mb-1">
              <b-form-group
                label="Kilometri effettivi"
                label-for="note"
              >
                <div class="d-flex flex-row">
                  <div class="mr-1">
                    <b-form-input
                      id="km_effettivi"
                      v-model="formData.km_effettivi"
                      placeholder=""
                      trim
                      :state="getValidationState(validationContext)"
                    />
                  </div>
                  <div>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="md"
                      variant="secondary"
                      @click="copyKm"
                    >
                      <feather-icon
                        icon="CopyIcon"
                        class="mr-50"
                        size="14"
                      />
                      COPIA KM STIMATI
                    </b-button>
                  </div>
                </div>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </validation-provider>

          <div class="mb-1">
            <b-form-group
              label="Nota di chiusura carico"
              label-for="note"
            >
              <b-form-textarea
                id="note"
                v-model="formData.note"
                placeholder=""
                trim
                rows="2"
              />
            </b-form-group>
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-2"
              type="submit"
            >
              CONFERMA E CHIUDI IL CARICO
            </b-button>

          </div>
        </div>

      </b-form>
    </b-overlay>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BButton, BFormTextarea, BFormInput, BFormInvalidFeedback, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max, integer } from '@validations_custom'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
// import store from '@/store'

export default {
  components: {
    // Bootstrap Vue Components
    BForm,
    BFormGroup,
    BButton,
    BFormTextarea,
    BFormInput,
    BFormInvalidFeedback,
    BOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    hide: Function,
    cargo_data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      max,
      integer,
      showOverlay: false,
      formLoading: false,
      formSending: false,
    }
  },
  watch: {
    cargo_data: {
      handler(newVal) {
        this.resetFormData()
        this.cargo_data = newVal
      },
      deep: true,
    },
  },
  methods: {
    submitForm() {
      this.showOverlay = true
      this.formSending = true

      const SubmitData = { ...this.formData, c_carico: this.cargo_data.c_carico }
      console.log(SubmitData)
      this.$http.post('/cargo/close', SubmitData).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Carico chiuso',
            icon: 'CheckIcon',
            variant: 'success',
            text: `Carico ${this.cargo_data.c_carico} chiuso con successo.`,
          },
        })
        this.hide()
        this.$emit('cargoClosed')
      }).catch(() => {
        this.showOverlay = false
      })
    },
    copyKm() {
      if (this.cargo_data.km_previsti) {
        this.formData.km_effettivi = this.cargo_data.km_previsti
      }
    },
  },
  setup(props, context) {
    const blankFormData = {
      km_effettivi: null,
      note: '',
    }

    const formData = ref(JSON.parse(JSON.stringify(blankFormData)))

    const resetFormData = () => {
      formData.value = JSON.parse(JSON.stringify(blankFormData))
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetFormData)

    return {
      formData,
      refFormObserver,
      getValidationState,
      resetForm,
      resetFormData,
      close,
    }
  },
}
</script>

<style lang="scss">
@import "~/src/assets/scss/variables/_variables.scss";

.close-cargo-form {

  min-height: 260px;

  label {
    font-size: 1rem !important;
  }

  p{
    line-height: 1.1;
    small{
      line-height: 1.1;
    }
  }

  .form-section-title{
    padding: 8px 8px 6px;
    background: #f6f6f6;
    line-height: 1.1;
  }

  .invalid-feedback{
    display: inline-block;
  }

}
</style>
