<template>
    <div>
        <b-row class="mb-1">
            <b-col cols="12">
                <b-alert show variant="primary">

                    <h4 class="alert-heading">ATTENZIONE! INSTALLAZIONE NUOVA APP AUTISTI</h4>
                    <p class="mx-2 my-2">
                        Entro <strong>Lunedì 24 Giugno</strong> tutti gli autisti dovranno aver installato la nuova App per la gestione delle consegne e la firma dei DDT.<br />
                        La nuova App non prevede l'utilizzo dell'App UbSign e Firma Grafocerta. Le loro funzionalità sono state integrate nell'App stessa.<br />
                        Eventuali consegne già iniziate con la precedente versione dell'app vanno chiuse prima di passare alla nuova versione.<br />
                        Assicurati di consultare la guida scaricabile qui in basso per installare correttamente la nuova app. Ti ringraziamo per la collaborazione.
                    </p>
                    <div>
                        <b-button href="https://pim.arredo3.it/portale-vettori/Installazione-nuova-App-Autisti-2024.pdf"
                                  download
                                  target="_blank"
                                  class="mx-2 mb-2 mt-0"
                                  size="sm" v
                                  ariant="primary">
                            Guida installazione
                        </b-button>
                    </div>
                    <!--
                    <p class="mx-2 my-2">
                        <strong>Lunedì 22 Aprile</strong>, ci sarà un'attività straordinaria di <strong>aggiornamento dei sistemi</strong>.
                        Durante l'intera giornata, l'uso dell'<strong>app UbSign</strong> per scaricare e firmare documenti <strong>non sarà disponibile</strong>.
                        A partire da <strong>martedì 23 Aprile</strong>, sarà necessario utilizzare la <strong>nuova versione dell'app UbSign</strong>,
                        disponibile per il download qui sotto.<br />
                        Assicurati di consultare la guida per installare correttamente la nuova app. Ti ringraziamo per la collaborazione.
                    </p>
                    <div>
                        <b-button href="https://pim.arredo3.it/portale-vettori/ubsign_arredo3_prod_v4_0_3.apk"
                                  download
                                  target="_blank"
                                  class="mx-2 mb-2 mt-0"
                                  size="sm"
                                  variant="danger">
                            Scarica Installer Ubisign (valido dal 23/04)
                        </b-button>
                        <b-button href="https://pim.arredo3.it/portale-vettori/installazione-nuova-app-ubsign.pdf"
                                  download
                                  target="_blank"
                                  class="mx-2 mb-2 mt-0"
                                  size="sm" v
                                  ariant="primary">
                            Guida installazione
                        </b-button>
                    </div>
                    -->
                </b-alert >
            </b-col>
        </b-row>

    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BButton,
    BOverlay,
    BAlert
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BRow,
        BCol,
        BButton,
        BOverlay,
        BAlert
    },
    directives: {
        Ripple,
    },
    props: {
        note_data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            attachments: null,
            showOverlay: false,
        }
    }
}
</script>

<style scoped lang="scss">

</style>
