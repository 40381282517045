<template>
  <div class="page-cargo-list">

    <ubsign-update />

    <b-row class="mb-1">
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(99, 99, 99, 0.15)'"
          variant="outline-secondary"
          :disabled="showOverlay"
          @click="cargoInProgressList"
        >
          <feather-icon
            icon="RefreshCcwIcon"
            class="mr-50"
          />
          <span class="align-middle">AGGIORNA</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">

        <b-overlay
          :show="showOverlay"
        >
          <div class="cargo-list">
            <div v-if="rows.length">
              <b-card
                v-for="listItem in rows"
                :key="listItem.c_carico"
                class="w-100 cargo"
              >
                <b-row class="no-gutters">

                  <b-col
                    cols="12"
                  >
                    <b-badge
                      class="d-sm-inline d-block"
                      :variant="'light-' + statusVariant(listItem.stato_spedizioni)"
                    >

                      <b-spinner
                        v-if="listItem.stato_spedizioni == 'in-progress'"
                        style="width: 14px; height: 14px;"
                        class="mr-50"
                        small
                        label=""
                        type="grow"
                      />
                      {{ statusText(listItem.stato_spedizioni) }}
                    </b-badge>

                    <b-badge
                      v-if="!listItem.c_automezzo"
                      variant="danger"
                      class="ml-0 ml-sm-1 mt-50 mt-sm-0 d-sm-inline d-block"
                    >
                      <feather-icon
                        icon="TruckIcon"
                        class="mr-25 mt-sm-25 mt-0"
                      />
                      <span>Veicolo non impostato</span>
                    </b-badge>

                  </b-col>

                  <b-col
                    cols="12"
                    sm="10"
                    class="d-flex align-content-start justify-content-left flex-wrap"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        class="d-flex align-content-start justify-content-left flex-wrap"
                      >
                        <div class="primary w-100 pt-1 pb-50">
                          <h4 class="p-0 m-0">
                            {{ `[${listItem.c_carico}] ${listItem.ds_piano_carico}` }}
                          </h4>
                        </div>

                        <div
                          v-if="listItem.dt_partenza"
                          class="date mt-25 text-primary pr-1"
                        >
                          <span
                            v-b-tooltip.hover.v-dark
                            title="Data partenza"
                            class="text-important"
                          >
                            <feather-icon
                              icon="CalendarIcon"
                              size="15"
                            />
                            <small>
                              {{
                                new
                                  Intl.DateTimeFormat('en-GB').format(Date.parse(listItem.dt_partenza))
                              }}
                            </small>
                          </span>
                        </div>
                        <div
                          v-if="listItem.trasportatore"
                          class="date mt-25 text-important pr-1"
                        >
                          <span
                            v-b-tooltip.hover.v-dark
                            title="Autista"
                            class="text-important"
                          >
                            <feather-icon
                              icon="PhoneIcon"
                              size="15"
                            />
                            <small>
                              {{ listItem.trasportatore }}
                            </small>
                          </span>
                        </div>
                      </b-col>

                      <b-col
                        cols="12"
                        class="d-flex align-content-start justify-content-left flex-wrap pt-50"
                      >
                        <div
                          v-if="listItem.id_cassa"
                          class="pr-1"
                        >
                          <span>
                            <small>
                              <strong>CASSA:</strong> {{ listItem.id_cassa }}
                            </small>
                          </span>
                        </div>
                        <div
                          v-if="listItem.area_parcheggio"
                          class="pr-1"
                        >
                          <span>
                            <small>
                              <strong>A.PARCHEGGIO:</strong> {{ listItem.area_parcheggio }}
                            </small>
                          </span>
                        </div>
                        <div
                          v-if="listItem.n_km_percorsi"
                          class="pr-1"
                        >
                          <span>
                            <small>
                              <strong>KM PREVISTI:</strong> {{ parseInt(listItem.n_km_percorsi) }}
                            </small>
                          </span>
                        </div>

                        <div
                          v-if="parseInt(listItem.n_consegne_anomale)"
                          class="w-100"
                        >
                          <span>

                            <feather-icon
                              class="mr-25"
                              icon="AlertTriangleIcon"
                            />
                            <span>
                              <small>
                                <strong>Consegne con note:</strong> {{ listItem.n_consegne_anomale }}
                              </small>
                            </span>

                          </span>
                        </div>

                      </b-col>

                      <b-col
                        cols="12"
                        class="d-flex align-content-start justify-content-left flex-wrap pt-1 pb-50"
                      >
                        <div
                          v-if="parseInt(listItem.n_ddt_in_arxivar) === parseInt(listItem.ddt_digitali)"
                          class="pr-1"
                        >
                          <span>
                            <small>
                              <span
                                class="px-50 text-white rounded-circle mr-25"
                                :class="parseInt(listItem.n_ddt_scaricati) === parseInt(listItem.n_ddt_in_arxivar) ? 'bg-success' : 'bg-warning'"
                              />

                              <strong>DDT SCARICATI:</strong> {{
                                listItem.n_ddt_scaricati
                              }} su {{ listItem.n_ddt_in_arxivar }}
                            </small>
                          </span>
                        </div>
                        <div
                          v-else
                          class="pr-1"
                        >
                          <span>
                            <small>
                              <span
                                class="px-50 text-white rounded-circle bg-danger mr-25"
                              />
                              <span class="text-danger">
                                <strong>ANOMALIA: DDT in Arxivar </strong> {{
                                  listItem.n_ddt_in_arxivar
                                }} su {{ listItem.ddt_digitali }}
                              </span>
                            </small>
                          </span>
                        </div>

                        <div
                          v-if="parseInt(listItem.n_ddt_in_errore)"
                          class="pr-1"
                        >
                          <span class="text-danger">
                            <small>
                              <feather-icon
                                class="mr-25 text-danger"
                                icon="AlertTriangleIcon"
                                size="13"
                              />
                              <strong>DDT IN ERRORE:</strong> {{ listItem.n_ddt_in_errore }}
                            </small>
                          </span>
                        </div>

                      </b-col>

                      <b-col
                        cols="12"
                        class="d-flex align-items-center justify-content-start flex-wrap pt-1 pb-50"
                      >
                        <div
                          v-b-tooltip.hover.v-dark
                          title="Numero colli"
                          class="pr-1 pb-50"
                        >
                          <small>{{ parseInt(listItem.n_colli) }}</small>
                          <feather-icon
                            class="text-body"
                            icon="PackageIcon"
                            size="21"
                          />
                        </div>

                        <div
                          v-b-tooltip.hover.v-dark
                          title="Volume"
                          class="pr-1 pb-50"
                        >
                          <small>{{ listItem.n_volume + 'm³' }}</small>
                          <feather-icon
                            class="text-body"
                            icon="BoxIcon"
                            size="21"
                          />
                        </div>
                        <div
                          v-b-tooltip.hover.v-dark
                          v-b-modal.modal-orders
                          title="Numero ordini"
                          class="pr-1 pb-50"
                        >
                          <feather-icon
                            :badge="parseInt(listItem.n_ordini)"
                            badge-classes="bg-success"
                            class="text-body"
                            icon="ShoppingCartIcon"
                            size="21"
                          />
                        </div>

                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col
                    cols="2"
                    class="d-none d-sm-flex align-content-center justify-content-end flex-wrap pt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="btn-icon btn-next d-flex align-content-center justify-content-center border-0 h-100"
                      :to="{ name: 'cargo', params: { cargo: listItem.c_carico }}"
                    >
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="24"
                        class="align-self-center"
                      />
                    </b-button>
                  </b-col>

                  <b-col
                    v-if="parseInt(listItem.n_consegne) === (parseInt(listItem.n_consegne) - parseInt(listItem.n_consegne_da_fare))"
                    cols="12"
                    class="pb-0 mb-1 mt-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-toggle.sidebar-close-cargo
                      variant="warning"
                      @click="setInClosingCargoData(listItem.c_carico, parseInt(listItem.n_km_percorsi))"
                    >
                      <feather-icon
                        icon="TruckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">CHIUDI CARICO</span>
                    </b-button>
                  </b-col>

                  <b-col
                    cols="12"
                    class="pb-0"
                  >

                    <small>
                      <strong>CONSEGNE:</strong> {{
                        parseInt(listItem.n_consegne) - parseInt(listItem.n_consegne_da_fare)
                      }} su {{ listItem.n_consegne }}
                      <span
                        v-if="parseInt(listItem.n_consegne_in_corso)"
                      >
                        ({{ listItem.n_consegne_in_corso }} in corso)
                      </span>
                    </small>
                    <b-progress
                      :max="listItem.n_consegne"
                      :variant="statusVariant(listItem.stato_spedizioni)"
                      :class="'progress-bar-'+statusVariant(listItem.stato_spedizioni)"
                    >
                      <b-progress-bar :value="parseInt(listItem.n_consegne) - parseInt(listItem.n_consegne_da_fare)">
                        <strong>{{ parseInt(listItem.n_consegne) - parseInt(listItem.n_consegne_da_fare) }} /
                          {{ listItem.n_consegne }}</strong>
                      </b-progress-bar>
                    </b-progress>
                  </b-col>
                  <b-col
                    cols="12"
                    class="d-block d-sm-none align-items-center justify-content-lg-end justify-content-center flex-wrap pt-1"
                  >

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="secondary"
                      block
                      size="md"
                      :to="{ name: 'cargo', params: { cargo: listItem.c_carico }}"
                    >
                      <span class="align-middle">APRI</span>
                      <feather-icon
                        icon="ArrowRightIcon"
                        class="ml-50"
                        size="16"
                      />
                    </b-button>

                  </b-col>
                </b-row>
              </b-card>
            </div>
            <div v-else-if="!rows.length && !showOverlay">
              <b-alert
                show
                variant="danger"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="AlertTriangleIcon"
                  />
                  <span class="ml-25">Nessun carico assegnato al veicolo impostato.</span>
                </div>
              </b-alert>
            </div>
          </div>
        </b-overlay>
      </b-col>
    </b-row>

    <!-- Sidebar - Form chiusura carico -->
    <b-sidebar
      id="sidebar-close-cargo"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      no-header
      backdrop
      right
      :visible="showCloseCargoSidebar"
      @change="updateCloseCargoSidebar"
    >
      <template #default="{ hide }">
        <!-- Sidebar Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Chiusura carico
            <span
              v-if="closing_cargo_data.c_carico"
            >
              <strong>{{ closing_cargo_data.c_carico }}</strong>
            </span>
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <close-cargo-form
          :hide="hide"
          :cargo_data="closing_cargo_data"
          @cargoClosed="cargoInProgressList"
        />
      </template>
    </b-sidebar>

    <!-- END | Sidebar - Form chiusura carico -->

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BPagination,
  BFormGroup,
  BAlert,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardText,
  BOverlay,
  BListGroupItem,
  BListGroup,
  VBTooltip,
  BModal,
  VBModal,
  BButton,
  BSidebar,
  BFormCheckbox,
  BFormCheckboxGroup,
  VBToggle,
  BProgress,
  BProgressBar,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import UbsignUpdate from '../messages/UbsignUpdate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
// eslint-disable-next-line import/extensions
import store from '@/store/index'
import CloseCargoForm from '../forms/CloseCargoForm'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardText,
    BOverlay,
    BListGroupItem,
    BListGroup,
    BAlert,
    VBTooltip,
    BModal,
    VBModal,
    BButton,
    VBToggle,
    BSidebar,
    AppCollapse,
    AppCollapseItem,
    BFormCheckbox,
    BFormCheckboxGroup,
    BProgress,
    BProgressBar,
    BSpinner,

    CloseCargoForm,
    UbsignUpdate
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      emptyTableStatus: 'loading',
      showOverlay: false,
      rows: [],
      searchTerm: '',
      showCloseCargoSidebar: false,
      closing_cargo_data: {
        c_carico: null,
        km_previsti: null,
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'to-do': 'danger',
        'in-progress': 'warning',
        completed: 'success',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    statusText() {
      const statusText = {
        /* eslint-disable key-spacing */
        'to-do': 'Da fare',
        'in-progress': 'In corso',
        completed: 'Completato',
        /* eslint-enable key-spacing */
      }

      return status => statusText[status]
    },
  },
  created() {
    this.cargoInProgressList()
  },
  methods: {
    cargoInProgressList() {
      this.showOverlay = true

      this.$http.get('/cargo/in-progress/list/open').then(res => {
        this.rows = res.data.data.cargos

        this.showOverlay = false

        if (!res.data.data.cargos || res.data.data.cargos.length === 0) {
          this.emptyTableStatus = 'empty'
        }
      }).catch(error => {
        this.showOverlay = false
        this.emptyTableStatus = 'empty'
      })
    },
    updateCloseCargoSidebar() {
      this.showCloseCargoSidebar = !this.showCloseCargoSidebar
    },
    setInClosingCargoData(cCarico = null, expectedKilometers = null) {
      this.closing_cargo_data.c_carico = cCarico
      this.closing_cargo_data.km_previsti = expectedKilometers
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/core.scss";
@import "~/src/assets/scss/variables/_variables.scss";

.cargo-list {
  min-height: 60vh;

  .progress {
    height: 1.25rem;
  }

  .spinner-grow {
    animation: 1.3s linear infinite spinner-grow;
  }

  .btn-next {
    background: #ededed !important;

    &:hover{
      background: #ededed !important;
    }

    &.border-0 {
      border: none !important;
    }
  }

}


</style>
